import React, { useState, useRef, useEffect } from 'react';
import './ModelCreationPopup.css';

// module for errors on the screen
import ErrorMessage from './ErrorMessage';
import Tooltip from './Tooltip';


const ModelCreationPopup = ({ headers, existingModelNames, onClose, onCreateModel }) => {
    
    // for holding the information with the selected headers
    const [selectedHeaders, setSelectedHeaders] = useState({});
    const [options, setOptions] = useState({
        normalizeData: false,
        imputeData: false,
        earlyStopping: false,
    });


    // state variables for showing and setting the errors that we are dealing with
    const [error, setError] = useState('');
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorType, setErrorType] = useState('error');

    // function for showing the error popup
    const displayError = (message, type = 'error') => {
        setError(message);
        setIsErrorVisible(true);
        setErrorType(type); // Add a state for error type

        setTimeout(() => {
            setIsErrorVisible(false);
        }, 3000);
    };



    // check that the model name is valid
    const [modelName, setModelName] = useState('');
    const [isModelNameValid, setIsModelNameValid] = useState(true);

    useEffect(() => {
        // Validate the model name
        setIsModelNameValid(!existingModelNames.includes(modelName) && modelName !== '');
    }, [modelName, existingModelNames]);


    // handle typing into the input field
    const handleModelNameChange = (e) => {
        setModelName(e.target.value);
    };
    

    useEffect(() => {
        const handleClickOutside = (event) => {
            
            const element = document.querySelector('.popup-container');
            if (element && !element.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const handleHeaderClick = (headerLabel) => {
        setSelectedHeaders(prevHeaders => ({
            ...prevHeaders,
            [headerLabel]: !prevHeaders[headerLabel]
        }));
        
        // onHeaderSelect(headerLabel);

    };

    const handleOptionChange = (option) => {
        setOptions(prevOptions => ({ ...prevOptions, [option]: !prevOptions[option] }));
    };

    
    const handleCreateClick = async () => {
        
        // check that there are selected headers
        if (!selectedHeaders || Object.keys(selectedHeaders).length === 0) {
            displayError('Please select at least one header');
            return;
        }

        // check that the model name is valid
        if (!isModelNameValid) {
            displayError('The model name is not valid');
            return;
        }

        // Check if all headers are selected
        const numberOfSelectedHeaders = Object.values(selectedHeaders).filter(isSelected => isSelected).length;
        if (numberOfSelectedHeaders === headers.length) {
            displayError('Selecting all headers as labels is not allowed');
            return;
        }

    
        // Prepare label data to send to the API
        const labelData = Object.entries(selectedHeaders).reduce((acc, [label, isSelected]) => {
            if (isSelected) {
                acc.push(label); // Include only selected labels
            }
            return acc;
        }, []);
    
        // Check if any options are selected
        const selectedOptions = Object.entries(options).reduce((acc, [option, isSelected]) => {
            if (isSelected) {
                acc[option] = isSelected;
            }
            return acc;
        }, {});
    
        await onCreateModel && onCreateModel({
            modelName, // Include the model name
            headers: labelData,
            options: selectedOptions,
        });

        onClose();
    };

    return (
        <>
            <ErrorMessage message={error} isVisible={isErrorVisible} type={errorType} />
            
            <div className="popup-container">
                <h1>Model Config</h1>
                <hr className="divider" />

                <div className="section-label">
                    <Tooltip title="Model Name" body="Enter a unique name for your model. The name should not be one that you have used before and should generally be alphanumeric.">
                        <h3>Naming the Model</h3>
                    </Tooltip>
                </div>

                <div className="section-content">
                        <input
                            name="modelName"
                            type="text"
                            style={{ margin: "10px" }}
                            value={modelName}
                            onChange={handleModelNameChange}
                            placeholder="Model Name"
                            required
                        />
                    <div>
                        {isModelNameValid ? (
                            <p className="password-validation-feedback" style={{ color: 'green' }}>Model Name is Valid.</p>
                        ) : (
                            <p className="password-validation-feedback" style={{ color: 'red' }}>Model Name is not Valid.</p>
                        )}
                    </div>
                </div>
            
                <hr className="divider" />

                {/* Options Section */}
                <div className="section-label">
                    <Tooltip title="Model Options" body="Select the options that this model will use in the creation process.">
                        <h3>Options</h3>
                    </Tooltip>
                </div>

                <div className="section-content">
                    <div className="options">
                        <Tooltip title="Normalize Data" body="Selecting this option changes the data so that each category is normally distributed about the mean using a series of statistical techniques. This option is recommended. Any changes made to the label data can be undone.">
                            <label>
                                <input type="checkbox" checked={options.normalizeData} onChange={() => handleOptionChange('normalizeData')} />
                                Normalize Data
                            </label>
                        </Tooltip>
                        <Tooltip title="Impute Data" body="This option allows for the algorithm to fill missing data in the uploaded csv file.">
                            <label>
                                <input type="checkbox" checked={options.imputeData} onChange={() => handleOptionChange('imputeData')} />
                                Impute Data
                            </label>
                        </Tooltip>
                        <Tooltip title="Early Stopping" body="This option allows for the algorithm to pause training if it detects that there are no more improvements being made by training the model further.">
                            <label>
                                <input type="checkbox" checked={options.earlyStopping} onChange={() => handleOptionChange('earlyStopping')} />
                                Early Stopping
                            </label>
                        </Tooltip>
                        {/* <label>
                            <input type="checkbox" checked={options.label3} onChange={() => handleOptionChange('label3')} />
                            Label3
                        </label> */}
                    </div>
                </div>
                <hr className="divider" />

                {/* Selecting Headers Section */}
                <div className="section-label">
                    <Tooltip title="Select Data Labels" body="Select the columns of the data file that the model should be predicting. These labels will be used to train the model.">
                            <h3>Select Labels</h3>
                    </Tooltip>
                </div>
                
                <div className="section-content">
                    <div className="data-key">
                        {/* Key Section */}
                        <Tooltip title="Categorical Data" body="Data that is categorical in nature. For example, the state that someone lives in might be categorical data.">
                            <div className="key-item">
                                <span className="dot string"></span>Categorical
                            </div>
                        </Tooltip>
                    
                        <Tooltip title="Continuous Data" body="Data that is non-discrete in nature. For example, someone's age might be continuous in nature.">
                            <div className="key-item">
                                <span className="dot number"></span>Continuous
                            </div>
                        </Tooltip>
                    </div>
                    
                    <div className="headers-list">
                        {/* Headers List */}
                        {Array.isArray(headers) && headers.map((header, index) => (
                            <div
                                key={index}
                                className={`header-item ${selectedHeaders[header.label] ? 'selected' : ''}`}
                                onClick={() => handleHeaderClick(header.label)}
                            >
                                <span className={`dot ${header.type === 'string' ? 'string' : 'number'}`}></span>
                                {header.label}
                            </div>
                        ))}
                    </div>
                </div>
                
                <div className="button-container">
                    <button className="button-class" onClick={handleCreateClick}>Create!</button>
                </div>
            </div>
        </>
    );
};

export default ModelCreationPopup;
