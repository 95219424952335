import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';

export const useAuthenticatedRequest = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const makeRequest = async (url, method, data) => {
    let response;
    let token = auth.token;

    try {
      response = await axios({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`
        },
        data,
      });
    } catch (error) {
      
      if (error.response && error.response.status === 401) {
        
        // Token expired, try to refresh

        await auth.refreshToken();  
        token = auth.token; // Get the new token

        // Retry the request with the new token
        response = await axios({
          url,
          method,
          headers: {
            Authorization: `Bearer ${token}`
          },
          data,
        });
      } else {
        throw error;  // Rethrow other errors
      }
    }

    if (response && response.data && response.data.authenticated === false) {
      auth.logout();
      navigate('/');
    }

    return response ? response.data : null;
  };

  return makeRequest;
};
