import axios from 'axios';

const validateToken = async (token) => {
  try {
    const apiBaseUrl = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`;
    const response = await axios.post(`${apiBaseUrl}/api/validate-token`, { token });
    return response.data.valid;
  } catch (error) {
    console.error("Error validating token", error);
    return false;  // Assume the token is invalid on error
  }
};

export default validateToken;
