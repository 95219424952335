
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Analyze.css"

import ProfileWidget from './ProfileWidget' 
import { useAuth } from './useAuth';
import { useAuthenticatedRequest } from './useAuthenticatedRequest';
import { useSidebarContext } from './SidebarContext';
import Tooltip from './Tooltip';
// import CircleColumnsContainer from "./NetworkDesign"
// import ChooseModel from './ChooseModel'

import "./NetworkDesign.css"






function Analyze() {

    const auth = useAuth();
    const navigate = useNavigate();
    const makeRequest = useAuthenticatedRequest();

    const [models, setModels] = useState([]); // for storing models from the API
    const [selectedModelName, setSelectedModel] = useState(null); // for storing the selected model
    const [analyzingModel, setAnalyzingModel] = useState(false); // for storing whether we should display all of the information
    
    
    // for the importances of the models
    const [modelInputLabels, setModelInputLabels] = useState([]); // for displaying the analysis results
    const [generalImportanceValues, setGeneralImportanceValues] = useState([]); // for displaying the analysis results
    const [biasImportanceValues, setBiasImportanceValues] = useState([]); // for displaying the analysis results
    const [selectedInputDataFile, setSelectedInputDataFile] = useState(null); // the input data that we are predicting the outputs of
    
    // prediction analysis
    const [selectedPredictionIndextoAnalyze, setSelectedPredictionIndextoAnalyze] = useState("")
    const [inputImportanceValues, setInputImportanceValues] = useState([]); // for displaying the analysis results
    const [confidenceImportanceValues, setConfidenceImportanceValues] = useState([]); // for displaying the confidence results
    const [amplitudeCancellationImportance, setAmplitudeCancellationImportance] = useState([]); // for displaying the analysis results
    const [analyzingPrediction, setAnalyzingPrediction] = useState(false); // for storing whether we should display all of the information
    const inputDataFileInput = useRef(null);

    // information for the sidebar
    const { sidebarData, updateSidebarData } = useSidebarContext();
    
    
    // check if the prediction output is ready and get the output prediction
    const [isLoading, setIsLoading] = useState(false);
    const [isFileReady, setIsFileReady] = useState(false);

    // setting the variables that we should be watching for outsized importance on
    const [selectedProblemItems, setSelectedProblemInputs] = useState([]);
    
    // variable for whether we are visualizing the model or understanding the inputs
    const [auditInputViz, setAuditInputViz] = useState(true);
    
    // variable defining whether we are looking for problematic outputs or not
    const [analyzingInputsOfInterest, setAnalyzingInputsOfInterest] = useState(false);
    
    // this variable is for holding the indexes of the predictions with over-representation of the inputs of interest
    const [selectedProblemIdxs, setSelectedProblemIdxs] = useState([]);
    
    // this variable is for holding the inputs of the predictions with over-representation of the inputs of interest
    const [selectedProblemInputLabels, setSelectedProblemInputLabels] = useState([]);


    // drawing circles
    // TODO: Change so that this is better-coded later
    const [nodesByLayer, setNodesByLayer] = useState([]);
    const [infos, setInfos] = useState({});



    // function for resetting the file that we have staged and ready to go
    const resetPredictionFileInput = () => {
        // Check if the ref current property is not null
        if (selectedInputDataFile && selectedInputDataFile.current) {
            selectedInputDataFile.current.value = '';
        }
    };


    // all of the information from box 2
    const handlePredictionFileChange = (event) => {
        const inputDataFileInput = event.target.files[0];
        if (inputDataFileInput && inputDataFileInput.name.endsWith('.csv')) {
            setSelectedInputDataFile(inputDataFileInput);
        } else {
            alert('Please select a CSV prediction output file');
        }
    };




    // handle the upload of the file to our server
    const handlePredictionUpload = async () => {
        
        if (!selectedInputDataFile) {
            alert('Please select a file first!');
            return;
        }
        
        if (!selectedModelName) {
            alert('Please select a model first!');
            return;
        }

        if (!auth.isAuthenticated()) {
            navigate("/");
            return; 
        }

        const formData = new FormData();
        formData.append('model_name', selectedModelName);
        formData.append('pred_file', selectedInputDataFile);

        // update the sidebar data
        updateSidebarData({ selectedModelName: selectedModelName });

        // set the loading status for our prediction program
        setIsLoading(true);

        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            const data = await makeRequest(`${apiBaseUrl}/api/make_prediction`, 'post', formData);

            if (!data.predicted) {
                alert(data.message); // Don't need await here
            }
            
        } catch (error) {
            console.error('Error:', error);
            alert('Error uploading file: ' + error.message); // Use error.message to get the error description
        } finally {
            setIsLoading(false); // Stop loading
            setIsFileReady(true);
            resetPredictionFileInput();
        }

    };
    
    

    // handle the upload of the file to our server
    const handlePredictionAnalysis = async () => {
        
        if (!selectedPredictionIndextoAnalyze) {
            alert('Please input a prediction index first!');
            return;
        }
        
        if (!selectedModelName) {
            alert('Please select a model first!');
            return;
        }

        if (!auth.isAuthenticated()) {
            navigate("/");
            return; 
        }

        const formData = new FormData();
        formData.append('model_name', selectedModelName);
        formData.append('pred_idx', selectedPredictionIndextoAnalyze);

        // update the sidebar data
        updateSidebarData({ selectedModelName: selectedModelName, selectedPredictionIndex: selectedPredictionIndextoAnalyze});

        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            const data = await makeRequest({
                url: `${apiBaseUrl}/api/analyze_prediction`,
                method: 'POST',
                data: formData
            });
            
            if (!data.analyzed) {
                alert(data.message); 
            } else{
                setInputImportanceValues(data.backprop_importance)
                setAmplitudeCancellationImportance(data.outcome_delta)
                setConfidenceImportanceValues(data.confidence)
            }
            
        } catch (error) {
            console.error('Error:', error);
            alert('Error uploading file: ' + error.message); 
        }

    };
    
    
    
    // handle the upload of the file to our server
    const handlePredictionDownload = async () => {
        if (!selectedModelName) {
            alert('Please select a model first!');
            return;
        }
    
        if (!auth.isAuthenticated()) {
            navigate("/");
            return; 
        }
    
        const formData = new FormData();
        formData.append('model_name', selectedModelName);
        formData.append('download_flag', true);
    
        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiBaseUrl}/api/download-file`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${auth.token}`  // Include the auth token in the request headers
                },
                body: formData,
            });
    
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'prediction.csv'; // Filename
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            } else {
                const errorData = await response.json();
                alert(errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error downloading file: ' + error.message);
        }
    };
    
    
    
    
    // check if there is a prediction file ready for download
    const checkPredictionFile = async () => {
        
        
        if (!auth.isAuthenticated()) {
            navigate("/");
            return; 
        }
        
        if (!selectedModelName) {
            alert('Please select a model first!');
            return;
        }

        // const formData = new FormData();
        // formData.append('model_name', selectedModelName);
        // formData.append('download_flag', false);

        // set the loading status for our prediction program
        setIsFileReady(false);

        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            const data = await makeRequest(`${apiBaseUrl}/api/download-file`, 'POST', { selectedModelName, download_flag: false});
            
            console.log(data.message);

            if (data.downloaded){
                setIsFileReady(true);
            } else {
                setIsFileReady(false);
            }
    
        } catch (error) {
            console.error('Error:', error);
            alert('Error checking file: ' + error.message);
        }

    };



    const handlePredictionNumberChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setSelectedPredictionIndextoAnalyze(newValue);
       
        // update the sidebar data
        updateSidebarData({ selectedPredictionIndex: newValue});

    };


    const handlePredictionButtonClick = () => {
        handlePredictionUpload();
    };


    const getModels = async () => {
        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            const data = await makeRequest(`${apiBaseUrl}/api/get_models`, 'get');
            setModels(data.model_list);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };


    // fetch models from the Flask API
    useEffect(() => {
        getModels();
    }, []);

    // handle selection of a model
    const selectModel = (model) => {
        setSelectedModel(model);

        // update the sidebar data
        updateSidebarData({ selectedModelName: model });

        
    };


    const analyzeModel = async () => {
        
        // Check the prediction file
        checkPredictionFile();
    
        if (selectedModelName) {
            setAnalyzingModel(true); // Indicate that analysis has started
    
            try {
                const apiBaseUrl = process.env.REACT_APP_API_PORT
                    ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                    : process.env.REACT_APP_API_URL;
                const data = await makeRequest(`${apiBaseUrl}/api/analyze_model`, 'post', {selectedModelName});
    
                if (data.analyzed) {
                    // Handle the successful response here
                    console.log('Analysis successful:', data);
                    setModelInputLabels(data.headers); 
                    setGeneralImportanceValues(data.input_importance); 
                    setBiasImportanceValues(data.bias_effect);
                } else {
                    // Handle the case where 'analyzed' is false
                    console.log('Analysis failed:', data.message);
                }
            } catch (error) {
                console.error('Error analyzing model:', error);
                alert('Error analyzing model. Please try again.');
            }
        }
    };
    
    
    
    // handle analyze button click
    const deleteModel = async () => {
        if (selectedModelName) {
            try {
                const apiBaseUrl = process.env.REACT_APP_API_PORT
                    ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                    : process.env.REACT_APP_API_URL;
                const data = await makeRequest(`${apiBaseUrl}/api/delete-model`, 'POST', {
                    selectedModelName
                });
                console.log(data);
    
                // After deletion, refresh the model list
                getModels();
                alert("Deleted Model");
            } catch (error) {
                console.error('Error deleting model:', error);
            }
        } else {
            alert('Please select a model first!');
        }
    };
    


    const sendProblemVariablesToApi = async () => {
        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            
            const data = await makeRequest(`${apiBaseUrl}/api/get-problematic-predictions`, 'post', { selectedProblemItems, model_name: selectedModelName });
    
            if (data.success) {
                setAnalyzingInputsOfInterest(true);
                setSelectedProblemIdxs(data.idxs);
                setSelectedProblemInputLabels(data.issue_input);
            } else {
                // Alert the message if the response contains a message in case of failure
                alert(data.message || "An error occurred");
            }
            
        } catch (error) {
            console.error('Error:', error);
            // Check if the error object has a response with a message
            if (error.response && error.response.data && error.response.data.message) {
                alert('Error: ' + error.response.data.message);
            } else {
                alert('Error: ' + error.message);
            }
        }
    };
    
    

    // handle clicking the item that we are interested in
    const handleItemClick = (item) => {
        if (selectedProblemItems.includes(item)) {
            setSelectedProblemInputs(selectedProblemItems.filter(i => i !== item));
        } else {
            setSelectedProblemInputs([...selectedProblemItems, item]);
        }
    };

    
    return (
        <div>

            <div className='total-container' style={{
                    display: 'flex',
                    flexWrap: 'wrap', // allows columns to wrap on smaller screens
                    justifyContent: 'flex-start', // align columns to the start (left side)
                    marginTop: '100px',
                    alignItems: 'flex-start', // align elements at the top
                    alignContent: 'flex-start',
                    flexDirection: 'row',
                    minHeight: '100vh', // viewport height
                    padding: '20px',
                    marginLeft: '5vw',
                }}>
                <div className='info-column-container'>
                    
                    <div className='sidebar-model-select' style={{ top: '9%' }}>
                    <h2>
                        <Tooltip title="Model Selection" body="Select which of the models you have access to you would like to analyze. Selecting a model here allows you to use it for prediction and find predictions with characteristics that you specify below.">Select Model</Tooltip>
                    </h2>
                        <ul className="select-model-section">
                            {models.map((model, index) => (
                                <li 
                                key={index} 
                                onClick={() => selectModel(model)}
                                style={{ 
                                    cursor: 'pointer', 
                                    backgroundColor: model === selectedModelName ? 'rgba(220, 173, 230, 0.6)' : 'transparent',
                                    padding: '5px'
                                }}
                                >
                                    {model}
                                </li>
                            ))}
                        </ul>
                        <div>
                            <button className="analyze-button" onClick={analyzeModel}>Select Model</button>
                        </div>
                        <div>
                            <button className="analyze-button" onClick={deleteModel}>Delete Selected Model</button>
                        </div>
                    </div>

                    <div className='sidebar-pred-select' style={{ top:'45.5%' }}>
                        
                        {!isLoading && <h2>
                                <Tooltip title="Prediction" body="Upload a data file below to make predictions on the input data. For now, please make sure that it is a csv file and the headers for the data columns are the same as the model expects! One can find the input data headers on the right once you have selected a model to analyze.">Prediction</Tooltip>
                            </h2>}
                        {isLoading && <h2>Loading...</h2>}

                        <div style={{ marginLeft:"30%" }}>
                            <input type="file" accept=".csv" onChange={handlePredictionFileChange} />
                        </div>
                        <div>
                            <button className="analyze-button" onClick={handlePredictionButtonClick} style={{ marginTop:'20px' }}>Make Predictions</button>
                        </div>
                        {isFileReady && (<div>
                            <button className="analyze-button" onClick={handlePredictionDownload} style={{ marginTop:'20px' }}>Download Prediction File</button>
                        </div>
                        )}
                    </div>

                    <div className='sidebar-pred-select' style={{ top:'74%' }}>
                        
                        <h2>
                            <Tooltip title="Analyze Predictions" body="Enter the index of the prediction from the output prediction file that you would like to understand below. This will give an in-depth breakdown of how the model thinks and why it is giving the prediction that it is.">Analyze Predictions</Tooltip>
                        </h2>

                        <div style={{ marginLeft:"0%" }}>
                            <label className="pred-label" htmlFor="predIdxInput">Enter Prediction Index:</label>
                            <input className="number-input" type="number" id="predIdxInput" onChange={handlePredictionNumberChange} />
                        </div>
                        <button className="analyze-button" onClick={handlePredictionAnalysis} style={{ marginTop:'20px' }}>Analyze Prediction</button>
                    </div>
                    
                    <div className='sidebar-pred-select' style={{ top:'100%',  }}>
                        <h2>
                            <Tooltip title="Find Problematic Predictions" body="After selecting inputs to the model, this function searches through the most recent prediction file for predictions where the specified inputs have a disproportionate representation and low confidence score in the output. For instance, if one selects input A and runs this funciton, Alaight searches for all predictions where input A is over-represented in the output and the model has low confidence in the prediction.">Find Problematic Predictions</Tooltip>
                        </h2>
                        {analyzingInputsOfInterest && (<button className="analyze-button" onClick={() => setAnalyzingInputsOfInterest(false)} style={{ marginTop:'20px' }}>Input Importance</button>)}
                        {!analyzingInputsOfInterest && (<button className="analyze-button" onClick={sendProblemVariablesToApi}style={{ marginTop:'20px' }}>Check Predictions</button>)}
                    </div>

                    {/* {!analyzingInputsOfInterest && (<h5 className='bias_importance'>BIAS IMPORTANCE:<br />{biasImportanceValues}</h5> )} */}

                </div>
            



                {/* for if we are going to be analyzing the input numbers */}
                {(<div className='info-column-container'>

                    <h2 className='list-header'>
                        <Tooltip title="Select Inputs" body="Select the inputs which you would like to perform operations such as changing the importance or finding predictions with these inputs as strongly predictive factors on.">Select<br />Inputs</Tooltip>
                    </h2>
                    <ul>
                        {modelInputLabels.map((modelInputLabel, index) => (
                            <li className="list-item" key={index} 
                                style={{ 
                                    backgroundColor: selectedProblemItems.includes(modelInputLabel) ? 'purple' : 'transparent'
                                }}
                                onClick={() => handleItemClick(modelInputLabel)}>
                                {modelInputLabel}
                            </li>
                        ))}
                    </ul>
                </div>)}
                        
                {!analyzingInputsOfInterest && (<div className='info-column-container'>
                        <h2 className='list-header'>
                            <Tooltip title="Importance Above Baseline" body="Explains how much predictive power each of the inputs has in the model. These change as node importances are manipulated through importance dampening and other methods.">Importance<br />Above<br />Baseline</Tooltip>
                        </h2>
                        <ul>
                            {generalImportanceValues.map((modelInputLabel, index) => (
                                <li key={index} className="list-item">
                                    {generalImportanceValues[index]}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {!analyzingInputsOfInterest && (<div className='info-column-container'>
                        
                        <h2 className='list-header'>
                            <Tooltip title="Prediction Sensitivity" body="Measures how much the output predicted by the neural network changes compared to a change in this input. Effectively, this explains how volatile the output is with respect to this input.">Prediction<br />Sensitivity</Tooltip>
                        </h2>

                        <ul>
                            {inputImportanceValues.map((modelInputLabel, index) => (
                                <li key={index}  className="list-item">
                                    {inputImportanceValues[index]}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                            
                {!analyzingInputsOfInterest && (<div className='info-column-container'>
                       
                        <h2 className='list-header'>
                            <Tooltip title="Amplitude Cancellation Delta" body="Describes a weighted measurement of methods to measure how much impact this input has on the output. Compared to Prediction Sensitivity, this measurment is better for understanding how large shifts in inputs affect the output.">Amplitude<br />Cancellation<br />Delta</Tooltip>
                        </h2>

                        <ul>
                            {amplitudeCancellationImportance.map((modelInputLabel, index) => (
                                <li key={index} style={{ fontSize: '20px', marginTop: '30px'}}>
                                    {amplitudeCancellationImportance[index]}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                            
                {!analyzingInputsOfInterest && (<div className='info-column-container'>
                        <h2 className='list-header'>
                            <Tooltip title="Confidence" body="Measures how confident the model is in its prediction for this data point.">Confidence</Tooltip>
                        </h2>
                        <ul>
                            {confidenceImportanceValues.map((modelInputLabel, index) => (
                                <li key={index} style={{ fontSize: '20px', marginTop: '30px'}}>
                                    {confidenceImportanceValues[index]}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                        
                {analyzingInputsOfInterest && (<div className='info-column-container'>
                        <h2 className='list-header'>
                            <Tooltip title="Prediction Index" body="Shows which row of the generated prediction csv file this prediction is describing.">Prediction<br />Index</Tooltip>
                        </h2>
                        <ul>
                            {selectedProblemIdxs.map((modelInputLabel, index) => (
                                <li key={index} style={{ fontSize: '20px', marginTop: '30px'}}>
                                    {selectedProblemIdxs[index]}
                                </li>
                            ))}
                        </ul>
                    </div>
                )} 
                            
                {analyzingInputsOfInterest && (<div className='info-column-container'>
                        <h2 className='list-header'>
                            <Tooltip title="Issue Label" body="Shows which of the problematic labels that the user has searched for has disproportionate representation in this prediction.">Issue<br />Label</Tooltip>
                        </h2>
                        <ul>
                            {selectedProblemInputLabels.map((modelInputLabel, index) => (
                                <li key={index} style={{ fontSize: '20px', marginTop: '30px'}}>
                                    {selectedProblemInputLabels[index]}
                                </li>
                            ))}
                        </ul>
                    </div>
                )} 

            </div>
            
            <div className="profile-widget-container">
                <ProfileWidget />
            </div>

        </div>
    );
}

export default Analyze;
