// Modal.js
import React from 'react';
import './Modal.css'; // Create a separate CSS file for modal styling

const Modal = ({ isOpen, title, content, onConfirm, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <h2>{title}</h2>
                <p>{content}</p>
                <div className="modal-actions">
                    <button className="button-class" onClick={onConfirm}>Confirm</button>
                    <button className="button-class" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
