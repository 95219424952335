// LandingPage.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation

// importing the logo for the website
import "./LandingPage.css"
import "./../App.css"

function LandingPage() {
  return (
    <div>
      <title>Alaight Home</title>
      <meta name="description" content="Landing page for the Alaight Institute" />

      <div className="landing-container">
        <div className="quote-wrapper">
            <header>
              <h1 className="quote-1">Welcome to the Future of Observability</h1>
            </header>
          <div>
              <Link to="/login">
                <button className="login-button">Authenticate</button>
              </Link>
          </div>
          <div>
              <button className="login-button" onClick={() => window.location.href = 'mailto:alaight.institute@gmail.com'}>
                Get in Touch
              </button>
          </div>
        </div>
      </div>
    </div>
  );

}

export default LandingPage;
