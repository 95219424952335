import React from 'react';
import TeamSection from './TeamSection';
import BlogPostCarousel from './BlogPostCarousel';

const AboutUs = () => {
    return (
        <div>
            <title>About the Alaight Institute</title>
            <div className="text-box-container">
                <TeamSection />
            </div>
            {/* <BlogPostCarousel /> */}
        </div>
    );
};

export default AboutUs;
