import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useAuth } from './useAuth';
import validateToken from './validateToken';
import ErrorMessage from './ErrorMessage';
import Tooltip from './Tooltip';
import { defaultPasswords, validatePassword } from './PasswordUtils';
import "./Login.css";
import "../App.css"



function Login() {

  const navigate = useNavigate();
  const auth = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // determines whether we are registering or not
  const [registering, setRegistering] = useState(false);

  // confirm that the password meets the requirements for registration
  const [passwordValidation, setPasswordValidation] = useState({
    lengthCheck: false,
    numberOrSpecialCharCheck: false,
    notDefaultPassword: true,
    samePassword: false,
  });

  const [registerInfo, setRegisterInfo] = useState({
    newUsername: '',
    displayName: '',
    newPassword: '',
    confirmPassword: '',
    userEmail: '',
    secretKey: '',
  });


  useEffect(() => {
    const checkToken = async () => {
        
      const storedToken = localStorage.getItem('token');
        if (storedToken) {
            const isValid = await validateToken(storedToken);

            if (isValid) {
                navigate('/upload');
            } else {
                localStorage.removeItem('token');
            }
        }

      };
        
    // upadte the validation status on change
    setPasswordValidation(validatePassword(registerInfo.newPassword, registerInfo.confirmPassword));

    setRegistering(registerInfo.newPassword.length > 0);
      
    checkToken();
  }, [navigate, registerInfo.newPassword, registerInfo.confirmPassword]);


  // state variables for the error message
  const [error, setError] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [errorType, setErrorType] = useState('error');

  const displayError = (message, type = 'error') => {
    setError(message);
    setIsErrorVisible(true);
    setErrorType(type); // Add a state for error type

    setTimeout(() => {
        setIsErrorVisible(false);
    }, 3000);
};


  const handleLogin = async () => {

    await auth.login(username, password);

    try {
      if (auth.isAuthenticated()) {
        navigate('/upload'); // Redirect to a protected route after successful login
      } else {
        setPassword('');
        displayError('Login failed. Please check your credentials.');
      }
    } catch (error) {
      setPassword('');
      displayError('Error during login: ' + error.message);
    }
  };
  
  const handleForgotPassword = () => {
    navigate('/forgot-password'); // Redirect to a protected route after successful login
  }


  
  const handleRegister = async () => {
    const { newPassword, confirmPassword } = registerInfo;
  
    if (newPassword !== confirmPassword) {
      displayError('Passwords do not match.');
      return; // Stop the registration process
    }
  
    if (newPassword.length < 8) {
      displayError('Password must be at least 8 characters long.');
      return;
    }
  
    if (!/[0-9]/.test(newPassword) && !/[!@#$%^&*]/.test(newPassword)) {
      displayError('Password must contain at least one number or special character.');
      return;
    }
  
    if (defaultPasswords.includes(newPassword)) {
      displayError('Using a default password is not allowed.');
      return;
    }

    
    try {
      const apiBaseUrl = process.env.REACT_APP_API_PORT
      ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
      : process.env.REACT_APP_API_URL;
      
      const response = await axios.post(`${apiBaseUrl}/api/register`, registerInfo);
      
      if (response.data.success) {
        localStorage.setItem('userEmail', registerInfo.userEmail);
        navigate("/confirm-email");
      
      } else {
        
        // Clear the registration fields regardless of the registration outcome
        setRegisterInfo({
          newUsername: '',
          displayName: '',
          newPassword: '',
          confirmPassword: '',
          userEmail: '',
          secretKey: ''
        });

        console.log(response.data.message);  
        displayError(response.data.message);
      }
    } catch (error) {
      // Handle errors from the server or network issues
      console.log(error.response ? "ERROR! " + error.response.data.message : 'An error occurred');
      displayError(error.response ? error.response.data.message : 'An error occurred');
    }
  }



  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterInfo(prevState => ({
      ...prevState,
      [name]: value
    }));  
  };



  return (

    <div>
      
      <title>Login Page</title>
      <meta name="description" content="The login page for Alaight's web portal to create proprietary machine learning models." />

      <ErrorMessage message={error} isVisible={isErrorVisible} type={errorType} />

      <div className="auth-container">


        <div className="header-section">
          <h1>Authentication</h1>
        </div>
        <div className="login-section">
          <h2>Login</h2>
          <div>
            <div className="input-group">
              <input 
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                required
                />
            </div>
            
            <div className="input-group">
              <input 
                type="password"
                value={password}
                onKeyDown={(e) => e.key === 'Enter' && handleLogin()}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
              </div>
            
            <div>
              <button className="button-class" style={{ cursor: "pointer", marginTop: "10px" }} onClick={handleLogin}>Login</button>
              <button className="button-class" style={{ cursor: "pointer", marginTop: "10px" }} onClick={handleForgotPassword}>Forgot Password</button>
            </div>
          </div>
        </div>
        <div className="register-section">
          <h2>Register</h2>
          <div>
            <div className="input-group">
              <Tooltip title="Username" body="This username will be used to login to the website."> 
                <input 
                  name="newUsername"
                  type="text"
                  value={registerInfo.newUsername} 
                  onChange={handleChange}
                  placeholder="New Username" 
                  style={{ flex: 1, width: '130%' }}
                  required
                />
              </Tooltip>
              <Tooltip title="Display Name" body="This will be the profile name that is displayed to your co-workers and on your profile. Please include only alphanumeric characters and spaces."> 
                <input 
                  name="displayName"
                  type="text"
                  value={registerInfo.displayName} 
                  onChange={handleChange}
                  placeholder="Display Name" 
                  style={{ flex: 1, width: '130%', marginLeft: '30%' }}
                  required
                />
              </Tooltip>
            </div>

          </div>
          <div style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }}>
            <div className="input-group">
              <Tooltip title="Password" body="Your account's password. Please ensure that your password is at least 8 characters long and contains either a special character or number."> 
                <input 
                    name="newPassword"
                    type="password"
                    value={registerInfo.newPassword} 
                    onChange={handleChange}
                    placeholder="New Password" 
                    style={{ flex: 1, width: '130%' }}
                    required
                  />
                </Tooltip>
              <Tooltip title="Confirm Password" body="Please confirm your password by entering it again here."> 
                <input 
                    name="confirmPassword"
                    type="password"
                    value={registerInfo.confirmPassword} 
                    onChange={handleChange}
                    placeholder="Confirm Password" 
                    style={{ flex: 1, width: '130%', marginLeft: '30%' }}
                    required
                    />
                </Tooltip>
              </div>
              {registering && (
                <div className="password-validation-feedback-box">
                  <div className="password-validation-feedback">
                    <p style={{ color: passwordValidation.lengthCheck ? 'green' : 'red' }}>Minimum 8 characters</p>
                    <p style={{ color: passwordValidation.numberOrSpecialCharCheck ? 'green' : 'red' }}>Contains number or special character</p>
                  </div>
                  <div className="password-validation-feedback">
                    <p style={{ color: passwordValidation.notDefaultPassword ? 'green' : 'red' }}>Not a default password</p>
                    <p style={{ color: passwordValidation.samePassword ? 'green' : 'red' }}>Passwords Match</p>
                  </div>
                </div>)}
            </div>

          <div>
            <div className="input-group">
              <Tooltip title="Email" body="This email will be used to send login codes, verify your institution affiliation, and reset passwords."> 
                <input
                  name="userEmail"
                  type="text"
                  value={registerInfo.userEmail}
                  onChange={handleChange}
                  placeholder="Email" 
                  style={{ flex: 1, width: '130%' }}
                  required
                  />
              </Tooltip>
              <Tooltip title="Access Token" body="If Alaight has provided you an access token, please input it here. This allows you to create an account on the website."> 
              <input
                name="secretKey"
                type="text"
                value={registerInfo.secretKey} 
                onChange={handleChange}
                placeholder="Access Token" 
                style={{ flex: 1, width: '130%', marginLeft: '30%' }}
                onKeyDown={(e) => e.key === 'Enter' && handleRegister()}
                required
                />
              </Tooltip>
            </div>

            <div>
              <button className="button-class" style={{ textAlign:"left", cursor: "pointer", marginTop: "10px" }} onClick={handleRegister}>Register</button>
            </div>  
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;