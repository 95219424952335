// to check for unique passwords
export const defaultPasswords = 
  ['123456',
  '123456789',
  'qwerty',
  'password',
  '12345',
  'qwerty123',
  '1q2w3e',
  '12345678',
  '111111',
  '1234567890',];

export const validatePassword = (password_1, password_2) => {
    
    // password 1
    const lengthCheck = password_1.length >= 8;
    const numberOrSpecialCharCheck = /[0-9]/.test(password_1) || /[!@#$%^&*]/.test(password_1);
    const notDefaultPassword = !defaultPasswords.includes(password_1);

    // password 2 equals 1
    const samePassword = password_1 === password_2;

    return {
        lengthCheck,
        numberOrSpecialCharCheck,
        notDefaultPassword,
        samePassword,
    };
};