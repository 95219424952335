import React, { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import ProfileWidget from './ProfileWidget' 
import { useSidebarContext } from './SidebarContext';
import { useAuthenticatedRequest } from './useAuthenticatedRequest';
import { useNavigate } from 'react-router-dom';
import Tooltip from './Tooltip';

import './ModelAudit.css';
import './Analyze.css'


const PredictionsPage = () => {
    const [predictions, setPredictions] = useState([]);
    const [selectedPredictions, setSelectedPredictions] = useState(new Set());

    // context parameters
    const auth = useAuth();
    const makeRequest = useAuthenticatedRequest();
    const navigate = useNavigate();

    const [models, setModels] = useState([]); // for storing models from the API
    const [selectedModelName, setSelectedModel] = useState(null); // for storing the selected model
    
    // information for the sidebar
    const { sidebarData, updateSidebarData } = useSidebarContext();

    // setting the variables that we should be watching for outsized importance on
    const [selectedProblemItems, setSelectedProblemInputs] = useState([]);
    
    // this variable is for holding the indexes of the predictions with over-representation of the inputs of interest
    const [selectedProblemIdxs, setSelectedProblemIdxs] = useState([]);
    
    // this variable is for holding the inputs of the predictions with over-representation of the inputs of interest
    const [selectedProblemInputLabels, setSelectedProblemInputLabels] = useState([]);

    const [analyzingModel, setAnalyzingModel] = useState(false); // for storing whether we should display all of the information
    
    // for the importances of the models
    const [modelInputLabels, setModelInputLabels] = useState([]); // for displaying the analysis results

    // for displaying the analysis results
    const [generalImportanceValues, setGeneralImportanceValues] = useState([]); 

    // for displaying the analysis results
    const [biasImportanceValues, setBiasImportanceValues] = useState([]); 

    // check whether the prediction file is ready for download
    const [isFileReady, setIsFileReady] = useState(false);
    
    // check whether we are currently reducing the predictive power of a node
    const [depressingNodeImportance, setDepressingNodeImportance] = useState(false);
    
    
    
    
    // fetch models from the Flask API
    useEffect(() => {
        getModels();
    }, []);

    // get the models that the user has from the api
    const getModels = async () => {
        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            const data = await makeRequest(`${apiBaseUrl}/api/get_models`, 'get');
            setModels(data.model_list);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };



    // handle selection of a model
    const selectModel = (model) => {
        setSelectedModel(model);

        // update the sidebar data
        updateSidebarData({ selectedModelName: model });
        
    };

    // handle deletion of models that we are not interested in
    const deleteModel = async () => {
        if (selectedModelName) {
            try {
                const apiBaseUrl = process.env.REACT_APP_API_PORT
                    ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                    : process.env.REACT_APP_API_URL;
                const data = await makeRequest(`${apiBaseUrl}/api/delete-model`, 'POST', {
                    selectedModelName
                });
                console.log(data);
    
                // After deletion, refresh the model list
                getModels();
                alert("Deleted Model");
            } catch (error) {
                console.error('Error deleting model:', error);
            }
        } else {
            alert('Please select a model first!');
        }
    };



    // handle depressing nodes in model
    const depressProblemInputImportance = async () => {
        const apiBaseUrl = process.env.REACT_APP_API_PORT
            ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
            : process.env.REACT_APP_API_URL;
            
        const data = await makeRequest(`${apiBaseUrl}/api/depress-node-importance`, 'post', { selectedProblemItems, model_name: selectedModelName });
    
        //TODO: Flesh out this logic
    }


    // handle clicking the item that we are interested in
    const handleItemClick = (item) => {
        if (selectedProblemItems.includes(item)) {
            setSelectedProblemInputs(selectedProblemItems.filter(i => i !== item));
        } else {
            setSelectedProblemInputs([...selectedProblemItems, item]);
        }
    };

    // check if there is a prediction file ready for download
    const checkPredictionFile = async () => {
        
        
        if (!auth.isAuthenticated()) {
            navigate("/");
            return; 
        }
        
        if (!selectedModelName) {
            alert('Please select a model first!');
            return;
        }

        // const formData = new FormData();
        // formData.append('model_name', selectedModelName);
        // formData.append('download_flag', false);

        // set the loading status for our prediction program
        setIsFileReady(false);

        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            const data = await makeRequest(`${apiBaseUrl}/api/download-file`, 'POST', { selectedModelName, download_flag: false});
            
            console.log(data.message);

            if (data.downloaded){
                setIsFileReady(true);
            } else {
                setIsFileReady(false);
            }
    
        } catch (error) {
            console.error('Error:', error);
            alert('Error checking file: ' + error.message);
        }

    };




    // handle the upload of the file to our server
    const handleNodeDepressClick = async () => {
            
        if (!selectedModelName) {
            alert('Please select a model first!');
            return;
        }

        if (!auth.isAuthenticated()) {
            navigate("/");
            return; 
        }

        // so that we change the message
        setDepressingNodeImportance(!depressingNodeImportance);

        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            const data = await makeRequest(`${apiBaseUrl}/api/model-depress-inputs`, 'post', { selectedProblemItems: selectedProblemItems, model_name: selectedModelName });
            
            if (data.success) {
                
            } else {
                // Alert the message if the response contains a message in case of failure
                alert(data.message || "An error occurred");
            }
            
        } catch (error) {
            console.error('Error:', error);
            // Check if the error object has a response with a message
            if (error.response && error.response.data && error.response.data.message) {
                alert('Error: ' + error.response.data.message);
            } else {
                alert('Error: ' + error.message);
            }
        }
    };



    // // handle the upload of the file to our server
    // const handleAuditClick = async () => {
        
    //     if (!selectedModelName) {
    //         alert('Please select a model first!');
    //         return;
    //     }

    //     if (!selectedPredictionIndextoAnalyze) {
    //         alert('Please input a prediction index first!');
    //         return;
    //     }

    //     if (!auth.isAuthenticated()) {
    //         navigate("/");
    //         return; 
    //     }

    //     if (auditInputViz) {

    //         const formData = new FormData();
    //         formData.append('model_name', selectedModelName);
    //         formData.append('pred_idx', selectedPredictionIndextoAnalyze);

    //         try {
    //             const apiBaseUrl = process.env.REACT_APP_API_PORT
    //                 ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
    //                 : process.env.REACT_APP_API_URL;
    //             const data = await makeRequest({
    //                 url: `${apiBaseUrl}/api/draw_model_with_prediction_discrepancies`,
    //                 method: 'POST',
    //                 data: formData
    //             });
    //             setNodesByLayer(data.nodes_by_layer); // Assuming nodes_by_layer is an array of arrays
    //             setInfos(data.infos); // Assuming infos is an object with info for each node
    //         } catch (error) {
    //             console.error('Error:', error);
    //         }

    //     }

    //     setAuditInputViz(!auditInputViz);


    // }

{/* for if we are checkin the actual node deviations
                {!auditInputViz && ( 
                    // <CircleColumnsContainer selectedModelName={selectedModelName} pred_idx={selectedPredictionIndextoAnalyze}/>
                    <div>
                        <h2 className='analyze-header-text' style={{ 
                            fontSize: '24px',
                            position: 'absolute',
                            left: '410px', 
                            top: '130px', 
                            maxWidth: 'calc(100% - 240px)' 
                        }}>Network Structure</h2>

                        <div className="circle-columns-container" style={{ left: '410px', top: '130px', }}>
                            {nodesByLayer.map((layer, columnIndex) => (
                                
                                // <div key={columnIndex} className="circle-column">
                                //     {layer.map((nodeValue, circleIndex) => {
                                       
                                //        const color = interpolateColor(nodeValue); // Assuming nodeValue is between 0 and 1
                                //         const info = infos[`${columnIndex}-${circleIndex}`];

                                //         return (
                                //             <Circle
                                //                 key={circleIndex}
                                //                 info={info}
                                //                 color={color}
                                //             />
                                //         );

                                //     })}

                                // </div>
                                <h3>hello</h3>
                            ))}
                        </div>
                    </div>
                )} */}

                // {auditInputViz && (<div>
                //     <button className="route-button" style={{ top:'90px' }} onClick={handleAuditClick}>Node Audit</button>
                // </div>)}
                // {!auditInputViz && (<div>
                //     <button className="route-button" style={{ top:'90px' }} onClick={handleAuditClick}>Input Audit</button>
                // </div>)}

// // This is going to define the circles that are going to represent the network that we are creating
// function Circle({ info, color }) {
//     const circleStyle = {
//         backgroundColor: color, 
//     };

//     return (
//         <div className="circle" style={circleStyle}>
//             <span className="tooltip">{info}</span>
//         </div>
//     );
// }

// function interpolateColor(value) {
//     // Assuming value is between 0 and 1
//     // White in RGB: (255, 255, 255), Deep Purple in RGB: (48, 25, 52)

//     const white = { r: 255, g: 255, b: 255 };
//     const deepPurple = { r: 48, g: 25, b: 52 };

//     // Interpolate each color component
//     // const r = Math.round(white.r + (deepPurple.r - white.r) * value);
//     // const g = Math.round(white.g + (deepPurple.g - white.g) * value);
//     // const b = Math.round(white.b + (deepPurple.b - white.b) * value);
//     const r = Math.round(deepPurple.r + (white.r - deepPurple.r) * value);
//     const g = Math.round(deepPurple.g + (white.g - deepPurple.g) * value);
//     const b = Math.round(deepPurple.b + (white.b - deepPurple.b) * value);

//     return `rgb(${r}, ${g}, ${b})`;
// }






    // useEffect(() => {
    //     fetchPredictions();
    // }, []);

    // const fetchPredictions = async () => {
    //     try {
    //         const apiBaseUrl = process.env.REACT_APP_API_PORT
    //             ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
    //             : process.env.REACT_APP_API_URL;

    //         const data = await makeRequest(`${apiBaseUrl}/api/get-predictions`, 'get');
    //         setPredictions(data.predictions || []);
    //     } catch (error) {
    //         console.error('Error fetching predictions:', error);
    //         alert('Error fetching predictions');
    //     }
    // };

    // handle selecting and getting the intial inputs for this model
    const analyzeModel = async () => {
        
        // Check the prediction file
        checkPredictionFile();
    
        if (selectedModelName) {
            setAnalyzingModel(true); // Indicate that analysis has started
    
            try {
                const apiBaseUrl = process.env.REACT_APP_API_PORT
                    ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                    : process.env.REACT_APP_API_URL;
                const data = await makeRequest(`${apiBaseUrl}/api/analyze_model`, 'post', {selectedModelName});
    
                if (data.analyzed) {
                    // Handle the successful response here
                    console.log('Analysis successful:', data);
                    setModelInputLabels(data.headers); 
                    setGeneralImportanceValues(data.input_importance); 
                    setBiasImportanceValues(data.bias_effect);
                } else {
                    // Handle the case where 'analyzed' is false
                    console.log('Analysis failed:', data.message);
                }
            } catch (error) {
                console.error('Error analyzing model:', error);
                alert('Error analyzing model. Please try again.');
            }
        }
    };



    // handle selecting predicitons from the list that we want to generate synthetic data on
    const handlePredictionSelect = (predictionId) => {
        const updatedSelection = new Set(selectedPredictions);
        if (updatedSelection.has(predictionId)) {
            updatedSelection.delete(predictionId);
        } else {
            updatedSelection.add(predictionId);
        }
        setSelectedPredictions(updatedSelection);
    };


    // this handles the retraining of the model
    const handleSubmit = async () => {
        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;

            await makeRequest(`${apiBaseUrl}/api/retrain-model`, 'post', {
                incorrectPredictions: Array.from(selectedPredictions),
            });

            alert('Incorrect predictions submitted for retraining');
        } catch (error) {
            console.error('Error submitting predictions:', error);
            alert('Error submitting predictions');
        }
    };





    return (
        <div>
            <div className='total-container' style={{
                    display: 'flex',
                    flexWrap: 'wrap', // allows columns to wrap on smaller screens
                    justifyContent: 'flex-start', // align columns to the start (left side)
                    marginTop: '100px',
                    alignItems: 'flex-start', // align elements at the top
                    alignContent: 'flex-start',
                    flexDirection: 'row',
                    minHeight: '100vh', // viewport height
                    padding: '20px',
                    marginLeft: '5vw',
                }}>

                <div className='info-column-container'>
                    
                    <div className='sidebar-model-select' style={{ top: '9%' }}>
                    <h2>
                        <Tooltip title="Model Selection" body="Select which of the models you have access to you would like to analyze. Selecting a model here allows you to use it for prediction and find predictions with characteristics that you specify below.">Select Model</Tooltip>
                    </h2>
                        <ul className="select-model-section">
                            {models.map((model, index) => (
                                <li 
                                key={index} 
                                onClick={() => selectModel(model)}
                                style={{ 
                                    cursor: 'pointer', 
                                    backgroundColor: model === selectedModelName ? 'rgba(220, 173, 230, 0.6)' : 'transparent',
                                    padding: '5px'
                                }}
                                >
                                    {model}
                                </li>
                            ))}
                        </ul>
                        <div>
                            <button className="analyze-button" onClick={analyzeModel}>Select Model</button>
                        </div>
                        <div>
                            <button className="analyze-button" onClick={deleteModel}>Delete Selected Model</button>
                        </div>
                    </div>

                    <div className='sidebar-pred-select' style={{ top:'45.5%',  }}>
                        <h2>
                            <Tooltip title="Depress Input Importance" body="From the nodes on the right, select which inputs' importance the user would like to see decreased. Then, run this function to minimize the impact that inputs have on the output without compromising model accuracy.">Depress Input Importance</Tooltip>
                        </h2>
                        <button className="analyze-button" onClick={handleNodeDepressClick}style={{ marginTop:'20px' }}>Realign Importance</button>
                    </div>

                    {/* <div className='sidebar-pred-select' style={{ top:'45.5%' }}>
                        
                        {!isLoading &&<h2>Predictions</h2>}
                        {isLoading && <h2>Loading...</h2>}

                        <div style={{ marginLeft:"30%" }}>
                            <input type="file" accept=".csv" onChange={handlePredictionFileChange} />
                        </div>
                        <div>
                            <button className="analyze-button" onClick={handlePredictionButtonClick} style={{ marginTop:'20px' }}>Make Predictions</button>
                        </div>
                        {isFileReady && (<div>
                            <button className="analyze-button" onClick={handlePredictionDownload} style={{ marginTop:'20px' }}>Download Prediction File</button>
                        </div>
                        )}
                    </div>

                    <div className='sidebar-pred-select' style={{ top:'74%' }}>
                        <h2>Analyze Predictions</h2>
                        <div style={{ marginLeft:"0%" }}>
                            <label className="pred-label" htmlFor="predIdxInput">Enter Prediction Index:</label>
                            <input className="number-input" type="number" id="predIdxInput" onChange={handlePredictionNumberChange} />
                        </div>
                        <button className="analyze-button" onClick={handlePredictionAnalysis} style={{ marginTop:'20px' }}>Analyze Prediction</button>
                    </div>
                    
                    <div className='sidebar-pred-select' style={{ top:'100%',  }}>
                        <h2>Find Problematic Predictions</h2>
                        {analyzingModel && (<button className="analyze-button" onClick={() => setanalyzingModel(false)} style={{ marginTop:'20px' }}>Input Importance</button>)}
                        {!analyzingModel && (<button className="analyze-button" onClick={sendProblemVariablesToApi}style={{ marginTop:'20px' }}>Check Predictions</button>)}
                    </div> */}

                </div>

                {/* for if we are going to be analyzing the input numbers */}
                {(<div className='info-column-container'>

                    <h2 className='list-header'>
                        <Tooltip title="Select Inputs" body="Select the inputs which you would like to perform operations such as changing the importance or finding predictions with these inputs as strongly predictive factors on.">Select Inputs</Tooltip>
                    </h2>
                    <ul>
                        {modelInputLabels.map((modelInputLabel, index) => (
                            <li className="list-item" key={index} 
                                style={{ 
                                    backgroundColor: selectedProblemItems.includes(modelInputLabel) ? 'purple' : 'transparent'
                                }}
                                onClick={() => handleItemClick(modelInputLabel)}>
                                {modelInputLabel}
                            </li>
                        ))}
                    </ul>
                </div>)}
                
                {analyzingModel && (<div className='info-column-container'>
                        <h2 className='list-header'>
                            <Tooltip title="Importance Above Baseline" body="Explains how much predictive power each of the inputs has in the model. These change as node importances are manipulated through importance dampening and other methods.">Importance<br />Above<br />Baseline</Tooltip>
                        </h2>
                        <ul>
                            {generalImportanceValues.map((modelInputLabel, index) => (
                                <li key={index} className="list-item">
                                    {generalImportanceValues[index]}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

            </div>

            <div className="profile-widget-container">
                <ProfileWidget />
            </div>

        </div>
    );

    // return (
    //     <div className="predictions-container">
    //         <h1>Model Predictions</h1>
    //         {predictions.map((prediction) => (
    //             <div key={prediction.id} className="prediction-item">
    //                 <label>
    //                     <input
    //                         type="checkbox"
    //                         checked={selectedPredictions.has(prediction.id)}
    //                         onChange={() => handlePredictionSelect(prediction.id)}
    //                     />
    //                     {prediction.value}
    //                 </label>
    //             </div>
    //         ))}
    //         <button className="submit-btn" onClick={handleSubmit}>Submit Incorrect Predictions</button>
    //     </div>
    // );
};

export default PredictionsPage;
