import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import "./Login.css";

function ConfirmRegistration({ userEmail }) {
    const navigate = useNavigate();
    const [verificationCode, setVerificationCode] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        const storedEmail = localStorage.getItem('userEmail') || userEmail;
        setEmail(storedEmail);
    }, [userEmail]);

    const handleSendCode = async () => {
        const apiBaseUrl = process.env.REACT_APP_API_URL;
        const apiPort = process.env.REACT_APP_API_PORT;
        const apiUrl = apiPort ? `${apiBaseUrl}:${apiPort}` : apiBaseUrl;

        console.log("verificationCode: " + verificationCode);
        console.log("email: " + email);

        try {
            const response = await axios.post(`${apiUrl}/api/confirm-email-registration`, {
                email: email,
                verificationCode: verificationCode
            });

            if (response.data.success) {
                alert(response.data.message);
                navigate('/login');
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error during API call:', error);
            alert('An error occurred while verifying the code.');
        }
    }

    return (
        <div>
            <div className="auth-container">
                <div className="header-section">
                    <h1>Authentication</h1>
                </div>
                <div className="login-section">
                    <h2>Input Authentication Code</h2>
                    <h4>Please enter verification code sent to email below</h4>
                    <div className="text-box-container">
                        <textarea 
                            className="text-box"
                            type="text"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            placeholder="Verification Code"
                            required
                        />
                    </div>
                    <button className="button-class" style={{ cursor: "pointer" }} onClick={handleSendCode}>Verify</button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmRegistration;
