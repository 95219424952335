import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLogout } from './useLogout';

import { TooltipVisibilityProvider, useTooltipVisibility } from './TooltipVisibilityContext';

import "./ProfileWidget.css";

const ProfileWidget = () => {
    
    // state variables holding information about whether the profile widget is open or not
    const [isOpen, setIsOpen] = useState(false);
    const handleLogout = useLogout();
    const display_name = localStorage.getItem('display_name') || 'User';

    // functions for hiding and showing the tool tips
    const { hideTooltips, showTooltips, clearCurrentTooltips } = useTooltipVisibility();
    
    const apiBaseUrl = process.env.REACT_APP_API_PORT
        ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
        : process.env.REACT_APP_API_URL;

    const toggleNav = () => {

        // clear the tooltops when we open
        if (isOpen) {
            clearCurrentTooltips();
            hideTooltips();
        } else {
            showTooltips()
        }

        // check whether it is open or not
        setIsOpen(!isOpen);
        
    };

    return (
        <div>
            <button className="navigate-button" onClick={toggleNav}>Navigate</button>
            <div className={`overlay ${isOpen ? 'overlay-active' : ''}`} onClick={toggleNav}></div>
            <div className={`side-nav ${isOpen ? 'is-nav-open' : ''}`}>
                <div className="nav-header">
                    <img className="profile-image" src={`${apiBaseUrl}/api/alaight_icon`} alt="Profile Icon" />
                    <div className="user-name">{display_name}</div>
                </div>
                <Link to="/upload" className="nav-item">Upload</Link>   
                <Link to="/predictions-analyze" className="nav-item">Analyze Predictions</Link>
                <Link to="/audit-model" className="nav-item">Audit Model</Link>
                <Link to="/update-model" className="nav-item">Update Model</Link>
                <Link to="/untrain-model" className="nav-item">Untrain Model</Link>
                <Link to="/current-processes" className="nav-item">Current Jobs</Link>
                <Link to="/profile" className="nav-item">Profile</Link>
                <div className="nav-item" onClick={handleLogout}>Logout</div>
            </div>
        </div>
    );
};

export default ProfileWidget;
