// Logout.js
import React, {  useEffect } from 'react';
import { useLogout } from './useLogout';
import './../App.css';

const Logout = () => {
    
    const handleLogout = useLogout();

    useEffect(() => {
        // Check if the username is present in local storage
        const userName = localStorage.getItem('display_name');
       
        if (!userName) {
            
            // If no username, trigger the logout
            handleLogout();

        }
    }, [handleLogout]);

    return <button className="logout-button" onClick={handleLogout}>Log out</button>;
};

export default Logout;

