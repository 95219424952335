import React from 'react';
import logo from './logo.png'; // import the logo image

function TopRightLogo() {
    const handleClick = () => {
        window.location.href = '/';
    };

    return (
        <img
            src={logo}
            alt="Alaight Logo"
            onClick={handleClick}
            style={{
                position: 'absolute',
                top: 30,
                left: 30, 
                height: '50px',
                cursor: 'pointer' 
            }}
        />
    );
}

export default TopRightLogo;
