// SidebarContext.js
import React, { createContext, useState, useContext } from 'react';

const SidebarContext = createContext();

export const useSidebarContext = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
    const [sidebarData, setSidebarData] = useState({
        selectedModelName: null,
        selectedPredictionIndex: null,
        selectedProblemInputs: null,
    });

    const updateSidebarData = (data) => {
        setSidebarData(prevState => ({ ...prevState, ...data }));
    };

    return (
        <SidebarContext.Provider value={{ sidebarData, updateSidebarData }}>
            {children}
        </SidebarContext.Provider>
    );
};
