import { jwtDecode } from 'jwt-decode';

import validateToken from './validateToken';

const isTokenExpired = (token) => {

  // Check if the token is misformatted
  if (!token || token.split('.').length !== 3) {
    // Invalid token format
    alert('Token Misformatted! ' + token)
    return true;
  }

  try {
    const decoded = jwtDecode(token);
    const currentDate = new Date();

    // JWT exp is in seconds
    return decoded.exp * 1000 < currentDate.getTime();
  } catch (error) {
    console.error("Failed to decode token", error);
    return true;
  }
};

export default isTokenExpired;
