import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import isTokenExpired from "./isTokenExpired";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));

  const refreshToken = async () => {
    
    const refresh_token = localStorage.getItem('refresh_token');
    if (!refresh_token) return;
  
    try {
      const apiBaseUrl = process.env.REACT_APP_API_PORT
        ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
        : process.env.REACT_APP_API_URL;
  
      const response = await axios.post(`${apiBaseUrl}/api/refresh-token`, { refresh_token });
      const { auth_token } = response.data;

  
      if (auth_token) {
        localStorage.setItem('token', auth_token);
        setToken(auth_token);
      } else {
        logout();  // Logout if refresh fails
      }
    } catch (error) {
      console.error('Token refresh failed:', error);
      logout();  // Logout if refresh fails
    }
  };
  

  useEffect(() => {
    // Setup an interval for refreshing the token
    const interval = setInterval(() => {
      if (isTokenExpired(token)) {
        refreshToken();
      }
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => clearInterval(interval);
  }, [token]);
  
  // function for logging into the website with the specified username and password
  const login = async (username, password) => {
    try {

      const apiBaseUrl = process.env.REACT_APP_API_PORT
        ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
        : process.env.REACT_APP_API_URL;
      
        // console.log("PINGING SERVER: " + apiBaseUrl + "/api/login");
      
      const response = await axios.post(`${apiBaseUrl}/api/login`, { username: username, password: password });
      const { auth_token, refresh_token, display_name } = response.data;
      
      if (auth_token && refresh_token) {
        
        localStorage.setItem('token', auth_token);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem('display_name', display_name);
        setToken(auth_token);

      } else {

        console.error('Login failed: Invalid token received');

      }
    } catch (error) {

      console.error('Login failed:', error.response.data.message);

    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('display_name');
    setToken(null);
  };

  const isAuthenticated = () => {
    const storedToken = localStorage.getItem('token');
    return storedToken != null && !isTokenExpired(storedToken);
  };
  
  useEffect(() => {
    // Update the token state based on localStorage
    const storedToken = localStorage.getItem('token');
    if (storedToken && !isTokenExpired(storedToken)) {
      setToken(storedToken);
    } else {
      setToken(null);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ token, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
