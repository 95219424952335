// Tooltip.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './Tooltip.css';

import { useTooltipVisibility } from './TooltipVisibilityContext';

const Tooltip = ({ children, title, body }) => {
    
    // checking whether the tooltip component should be visible or not
    const { isVisible } = useTooltipVisibility();

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });

    let timeout;
    const showTooltip = (e) => {
        timeout = setTimeout(() => {
            setTooltipVisible(true);
            setPosition({
                top: e.clientY,
                left: e.clientX
            });
        }, 300); // Delay to show tooltip is now 1000 milliseconds (1 second)
    };

    const hideTooltip = () => {
        clearTimeout(timeout); // Use clearTimeout instead of clearInterval
        setTooltipVisible(false);
    };

    useEffect(() => {
        return () => {
            clearTimeout(timeout); // Use clearTimeout for cleanup
        };
    }, []);

    return (
        <>
            {isVisible && ReactDOM.createPortal(
                tooltipVisible && (
                    <div className={`tooltip-box ${tooltipVisible ? 'visible' : ''}`} style={{ top: position.top, left: position.left }}>
                        <div className="tooltip-title">{title}</div>
                        <div className="tooltip-separator"></div>
                        <div className="tooltip-body">{body}</div>
                    </div>
                ),
                document.getElementById('portal-root')
            )}
            <div onMouseOver={showTooltip} onMouseOut={hideTooltip}>
                {children}
            </div>
        </>
    );
};

export default Tooltip;
