
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import "./Upload.css"

// for closing tooltips when we want to
import { TooltipVisibilityProvider, useTooltipVisibility } from './TooltipVisibilityContext';

// module for errors on the screen
import ErrorMessage from './ErrorMessage';

import ProfileWidget from './ProfileWidget'
import { useAuth } from './useAuth';
import ProgressBar from './ProgressBar'
import { useAuthenticatedRequest } from './useAuthenticatedRequest';
import Tooltip from './Tooltip';
import ModelCreationPopup from './ModelCreationPopup'
import Overlay from './Overlay';
// import ChooseModel from './ChooseModel'


function Upload() {

    const auth = useAuth();
    const navigate = useNavigate();
    const makeRequest = useAuthenticatedRequest();

    // have we started creating a model
    const [isCreating, setIsCreating] = useState(false);

    // all of the data that we are getting from the top box
    const [file_names, setFileNames] = useState([]);
    const [selectedFileName, setselectedFileName] = useState('');
    const [modelNameInput, setModelNameInput] = useState('');

    // all of the data that we are getting from the bottom box
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileInputValue, setFileInputValue] = useState("");
    
    // checking for the status of the input of the file
    const fileInputRef = useRef();

    // state variables for the progress bar to hold what job we should be analyzing
    const [modelJobString, setModelJobString] = useState(null);

    // the headers for the popup
    const [showPopup, setShowPopup] = useState(false);
    const [headersData, setHeadersData] = useState([]);

    // for hiding the tool tips when we want to
    const { hideTooltips, showTooltips, clearCurrentTooltips } = useTooltipVisibility();

    // for the model names so that we know that we don't have a duplicate name
    const [modelNames, setModelNames] = useState([]);
    
    // state variables for showing and setting the errors that we are dealing with
    const [error, setError] = useState('');
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorType, setErrorType] = useState('error');

    const displayError = (message, type = 'error') => {
        setError(message);
        setIsErrorVisible(true);
        setErrorType(type); // Add a state for error type

        setTimeout(() => {
            setIsErrorVisible(false);
        }, 3000);
    };



    // get all of the files associated with this account from the server
    // the models are included in this
    const fetchFiles = async () => {

        // start with getting the data file names that we already have from the server
        if (!auth.isAuthenticated()) {
            navigate("/");
        }

        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            const response = await makeRequest(`${apiBaseUrl}/api/files`, 'get');
            setFileNames(response.files)
        } catch (error) {
            displayError("Fetch error! " + error);
            console.error("Fetch error! " + error);
        }


        // get the model names from the server
        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            const data = await makeRequest(`${apiBaseUrl}/api/get_models`, 'get');
            setModelNames(data.model_list);
        } catch (error) {
            displayError('Error fetching models:', error);
            console.error('Error fetching models:', error);
        }
        
    };
    
    // // reset the model creation progress
    // const resetCreationProgress = async () => {
    //     try {
    //         const apiBaseUrl = process.env.REACT_APP_API_PORT
    //             ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
    //             : process.env.REACT_APP_API_URL;
    //         await makeRequest(`${apiBaseUrl}/api/reset_model_progress`, 'post');
    //         console.log('Model creation progress reset');
    //     } catch (error) {
    //         displayError("Reset Error: " + error);
    //         console.error("Reset Error: " + error);
    //     }
    // };

    useEffect(() => {
        
        if (!auth.isAuthenticated()) {
            navigate("/");
        }

        fetchFiles();
        // resetCreationProgress();
      }, []);


    // all of the information from box 1
    // this handles the new selection of a data file in the dropdown menu that we have
    const handleSelectedDataFileChange = async (e) => {
        const item = e.target.value;
        setselectedFileName(item);
    };


    // create the specified model
    const onCreateModel = async (modelData) => {
        // Model creation logic
        // Send modelData along with other necessary data to the API
        // You can include selectedFileName, modelNameInput, labelNamesInput, etc.
    
        try {
            
            setIsCreating(true);
    
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            const response = await makeRequest(`${apiBaseUrl}/api/create-model`, 'post', {
                // Include all necessary data for model creation
                selectedFileName: selectedFileName,
                modelName: modelNameInput,
                // labels: labelNamesInput,
                ...modelData
            });
    
            if (!response.uploaded) {
                displayError(response.message);
                console.error(response.message);
            } else {
                
                setModelJobString(response.modelJobString);
                
            }

    
        } catch (error) {
            // Error handling
        } finally {
            setShowPopup(false);
            showTooltips();
        }
    };


    // create the model using the selected datafile
    const handleOpenModelCreationPopup = async () => {
       
        // make sure that we have selected a file
        if (!selectedFileName) {
            displayError('Please select a file');
            console.error('Please select a file');
            return;
        }

        try {

            // get the headers from the api
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;

            const response = await makeRequest(`${apiBaseUrl}/api/get-file-headers`, 'post', {
                // Include all necessary data for model creation
                selectedFileName: selectedFileName,
            });

            
            // if this failed then return the error message
            if (!response.success) {
            
                displayError(response.message || 'Failed to create model');
                console.error(response.message || 'Failed to create model');
            
            } else {

                // set the headers that we can mark as labels for the model
                setHeadersData(response.headers);
            
                // Show the popup for model creation
                setShowPopup(true);
                clearCurrentTooltips();

            }   
        
        } catch (error) {
            // Handle any errors that occur during the API request
            displayError('Error creating model: ' + (error.response?.data?.message || error.message));
            console.error('Error creating model: ' + (error.response?.data?.message || error.message));
        }
        
    };
    

    // close the popup
    const handleClosePopup = () => {
        setShowPopup(false);
        showTooltips();
    }



    // function for resetting the file that we have staged and ready to go
    const resetFileInput = () => {
        
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }

        setSelectedFile(null); // Clear selected file state
        setFileInputValue("");
    };

    // all of the information from box 2
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.name.endsWith('.csv')) {
            setSelectedFile(file);
            setFileInputValue(event.target.value);
        } else {
            displayError('Please select a CSV file');
            console.error('Please select a CSV file');
        }
    };


    // handle the upload of the file to our server
    const handleUpload = async () => {
        
        // make sure that we have selected a file
        if (!selectedFile) {
            displayError('Please select a file first!');
            console.error('Please select a file first!');
            return;
        }

        // check if we are authenticated or not
        if (!auth.isAuthenticated()) {
            navigate("/")
        }

        // send the form data to the api
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const formData = new FormData();
            formData.append('file', selectedFile);

            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
            const data = await makeRequest(`${apiBaseUrl}/api/upload`, 'post', formData);

            if (data.uploaded) {

                displayError('File uploaded successfully', 'success');
                console.error('File uploaded successfully', 'success');

            } else {

                displayError(data.message);
                console.error(data.message);

            }
        } catch (error) {
            console.error('Error:', error);
            displayError('Error uploading file');
            console.error('Error uploading file');
        }

        resetFileInput();
    };


    // this function sends the files that are associated with creating the selected model
    const handleUploadButtonClick = () => {
        handleUpload();
        resetFileInput();
    };


    return (
        <div>

            <title>Upload Page</title>
            <meta name="description" content="The upload page for Alaight's website to create machine learning models." />

            <ErrorMessage message={error} isVisible={isErrorVisible} type={errorType} />

            <div className="upload-container">

                <div className="select-data-section">
                    {isCreating && (
                        <h2>Creating Model...</h2>
                    )}
                    {!isCreating && (
                        <h2>Select Data File for Model Creation</h2>
                    )}
                    <div>
                        <select value={selectedFileName} onChange={handleSelectedDataFileChange}>
                            <option value="">Select a Data File</option>
                            {file_names.map((file, index) => (
                                <option key={index} value={file}>{file}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <Tooltip title="Create Model" body="Choose which data to use to create the model, and specify the name and label columns that should be used.">
                            <button className="button-class" style={{ cursor: "pointer", marginTop:"20px" }} onClick={handleOpenModelCreationPopup}>
                                Create Model
                            </button>
                        </Tooltip>
                    </div>
                    
                    <ProgressBar isCreating={isCreating} setIsCreating={setIsCreating} filename={modelJobString ? modelJobString : null}/>
                    
                </div>
                
                <div className="select-data-section">
                    <h2>
                        <Tooltip title="Upload Data File" body="Upload data files to create machine learning models on here. For now, only csv files are supported but more formats are always being added!">Upload Data File for New Models</Tooltip>
                    </h2>
                        
                    <div>
                        <input ref={fileInputRef} className="file-input-pointer" type="file" accept=".csv" onChange={handleFileChange} />
                        <button className="button-class" style={{ cursor: "pointer" }} onClick={handleUploadButtonClick}>Upload</button>
                    </div>
                    

                </div>

                {showPopup && (
                    <>
                        <Overlay />
                        <ModelCreationPopup
                            headers={headersData}
                            existingModelNames={modelNames}
                            onClose={handleClosePopup}
                            onCreateModel={onCreateModel}
                        />
                    </>
                )}

            </div>

            <div className="profile-widget-container">
                <ProfileWidget />
            </div>
        </div>
    );
}

export default Upload;
