import React, { useState } from 'react';
import axios from 'axios';

import "./Login.css";


function ForgotPassword() {

    // define the variable and set method for email
    const [userEmail, setUserEmail] = useState('');

    // this function handles the registering 
    const handleResetUsername = async () => {

        try {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;

            const response = await axios.post(`${apiBaseUrl}/api/request-password-reset`, { userEmail });
            alert(response.data.message);

        } catch (error) {

            console.error('Error during registration', error);
            alert(error);

        }
    };

    return (
        <div>
            <title>Password Reset Portal</title>
            <meta name="description" content="Reset the password for Alaight account."/>

            <div className="auth-container">

                <div className="header-section">
                    <h1>Request Password Reset</h1>
                </div>

                <div className="login-section">
                    <div className="input-group">
                        <input 
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                            placeholder="User Email"
                            required
                        />
                    </div>
                    <button className="button-class" style={{ cursor: "pointer" }} onClick={handleResetUsername}>Request Password Reset Link</button>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;