import React from 'react';
import './Footer.css'; // Make sure your CSS file is correctly linked

function Footer() {
    return (
        <footer className="site-footer">
            <div className="footer-container">
                <div className="footer-about">
                    <h3>About Alaight Institute</h3>
                    <p>AI is a black box. It doesn't have to be.<br />Try Alaight's explainable platform today.</p>
                </div>
                <div className="footer-links">
                    <h3>Quick Links</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/blog">Blog</a></li>
                        <li><a href="mailto:alaight.institute@gmail.com">Support</a></li>
                        <li><a href="/get-certified">Get Certified</a></li>
                    </ul>
                </div>
                <div className="footer-contact">
                    <h3>Contact Us</h3>
                    <ul>
                        <li><a href="mailto:alaight.institute@gmail.com">Email: alaight.institute@gmail.com</a></li>
                    </ul>
                </div>
                <div className="footer-social">
                    <h3>Follow Us</h3>
                    <a href="https://www.linkedin.com/company/alaight/">LinkedIn</a>
                </div>
            </div>
            <div className="footer-bottom">
                © 2023 The Alaight Institute
            </div>
        </footer>
    );
}

export default Footer;
