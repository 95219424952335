import React, { createContext, useState, useContext } from 'react';

const TooltipVisibilityContext = createContext();

export const useTooltipVisibility = () => useContext(TooltipVisibilityContext);

export const TooltipVisibilityProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(true);

    const hideTooltips = () => setIsVisible(false);
    const showTooltips = () => setIsVisible(true);

    // New function to clear current tooltips
    const clearCurrentTooltips = () => {
        setIsVisible(false);
        // A brief timeout to ensure that tooltips are cleared before resetting the visibility
        setTimeout(() => {
            setIsVisible(true);
        }, 100); // 100ms timeout or adjust as needed
    };

    return (
        <TooltipVisibilityContext.Provider value={{ isVisible, hideTooltips, showTooltips, clearCurrentTooltips }}>
            {children}
        </TooltipVisibilityContext.Provider>
    );
};
