import { useAuth } from './useAuth';
import { useNavigate } from 'react-router-dom';
import { useAuthenticatedRequest } from './useAuthenticatedRequest';

export const useLogout = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const makeRequest = useAuthenticatedRequest();

    const handleLogout = async () => {

        if (auth.isAuthenticated()) {
            try {
                // Make an API request to logout
                const apiBaseUrl = process.env.REACT_APP_API_PORT
                    ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                    : process.env.REACT_APP_API_URL;
                await makeRequest(`${apiBaseUrl}/api/logout`, 'POST');

                // Proceed with client-side logout
                auth.logout();
                navigate('/');

            } catch (error) {
                console.error('Error during logout:', error);
                alert("Error during logout!")
            }
        } else {
            // Handle case where user is not authenticated
            console.log('User is not authenticated.');
            navigate('/login'); // Or show an appropriate message
        }
    };

    return handleLogout;
};
