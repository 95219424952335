import React from 'react';
import './ErrorMessage.css';

const ErrorMessage = ({ message, isVisible, type = 'error' }) => {
    if (!message || !isVisible) return null;

    const messageClass = type === 'success' ? 'success-message' : 'error-message';

    return <div className={messageClass}>{message}</div>;
};

export default ErrorMessage;
