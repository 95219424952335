import React from 'react';

import "./Billing.css"
import ProfileWidget from './ProfileWidget'

const Billing = () => {
    return (
        <div className="billing-page">
            <h1>Billing Page</h1>
            <p>This page is still being implemented!</p>
            
            <div className="profile-widget-container">
                <ProfileWidget />
            </div>
        </div>
    );
};

export default Billing;
