import React, { useState, useEffect } from 'react';
import ProgressBar from './ProgressBar';
import { useAuthenticatedRequest } from './useAuthenticatedRequest';
import ProfileWidget from './ProfileWidget'
import './CurrentJobs.css';

function CurrentJobs() {
    const [currentJobs, setCurrentJobs] = useState({});
    const makeRequest = useAuthenticatedRequest();

    useEffect(() => {
        const fetchCurrentJobs = async () => {
            try {
                const response = await makeRequest('/api/get-current-jobs', 'get');
                if (response && response.curr_jobs) {
                    setCurrentJobs(response.curr_jobs);
                }
            } catch (error) {
                console.error('Error fetching current jobs:', error);
            }
        };
        fetchCurrentJobs();
    }, []);

    const handleCancelJob = async (jobName, jobHash) => {
        try {
            
            // remove the file and the json file
            await makeRequest('/api/delete-model', 'post', { 
                selectedModelName: jobName,
                jsonFileHash: jobHash
            });

            setCurrentJobs(prevJobs => {
                const updatedJobs = { ...prevJobs };
                delete updatedJobs[jobName];
                return updatedJobs;
            });
        } catch (error) {
            console.error('Error cancelling job:', error);
        }
    };

    return (
        <div className='page-container'>
            <h1>Current Jobs</h1>
            <div className="current-jobs-container">
                {Object.entries(currentJobs).length === 0 ? (
                    <div className='no-jobs-container'>
                        <p>No current jobs.</p>
                    </div>
                ) : (
                    Object.entries(currentJobs).map(([jobName, jobHash]) => (
                        <div key={jobName} className="job-box">
                            <h3>{jobName}</h3>
                            <ProgressBar 
                                isCreating={true} 
                                setIsCreating={() => {}} 
                                filename={jobHash}
                            />
                            <div className="cancel-button" onClick={() => handleCancelJob(jobName, jobHash)}>
                                <span className="cancel-x">×</span>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <ProfileWidget />
        </div>
    );
}

export default CurrentJobs;
