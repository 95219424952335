import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import './BlogPostPage.css';

const BlogPostPage = () => {
  const [post, setPost] = useState(null);
  const { postId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      const apiBaseUrl = process.env.REACT_APP_API_PORT
        ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
        : process.env.REACT_APP_API_URL;

      try {
        const response = await axios.get(`${apiBaseUrl}/api/blogposts/${postId}`);
        setPost(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchPost();
  }, [postId]);

  const handleHome = () => {
    navigate("/blog");
  }

  if (!post) return <div>Loading...</div>;

  return (
        <div className="blog-post-page">
            <img src={post.imageUrl} alt={post.title} className="blog-post-page-image" />
            <div className="blog-post-page-title">{post.title}</div>
            <div className="blog-post-page-date">{post.date}</div>
            <p className="blog-post-page-text">{post.fullText}</p>
            <div className="blog-post-page-button-container">
                <button className="button-class" onClick={handleHome}>Home</button>
            </div>
        </div>
    );
};

export default BlogPostPage;
