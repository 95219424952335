import React from 'react';

import "./AboutUs.css"

const TeamSection = () => {
    return (
        <div className="team-section">
            <h2>Our Purpose</h2>
            <p>
                Alaight was founded with the purpose to create ethical and understandable artificial intelligence. 
                With Alaight's new platform, gone are the days of weighing the tradeoffs between data science tactics'
                benefits and drawbacks, as with Alaight's platform you gain the power of neural networks with the explainability 
                and modularity of regressions.<br /><br />

                Now is the time to contact Alaight for your company's data science and AI needs. With Alaight's state of the art, custom
                machine learning frameworks it is trivial to gain unparalleled power without losing clarity of the models 
                decision making processes.<br /><br />

                It's time to make the switch to powerful, observable AI. Try Alaight today.
            </p>
            <h2>About the Team</h2>
            <p>
                Alaight has a growing, highly capable team from around the world. Our team has deep expertise from the leading institutions and 
                companies in AI and has several esteemed publications under our belts.
            </p>
        </div>
    );
};

export default TeamSection;
