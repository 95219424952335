import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './App.css';


// // function MyApp2() {
// //   let content;
// //   if (isLoggedIn) {
// //     content = <AdminPanel />;
// //   } else {
// //     content = <LoginForm />;
// //   }
// //   return (
// //     <div>
// //       {content}
// //     </div>
// //   );
// // }



import TopRightLogo from "./components/TopRightLogo";

import Login from './components/Login';
import Logout from "./components/Logout"
import ForgotPassword from "./components/ForgotPassword"
import ResetPassword from "./components/ResetPassword"
import ConfirmRegistration from "./components/ConfirmRegistration"

import Upload from './components/Upload';
import Analyze from './components/Analyze';
import Billing from './components/Billing';
import AboutUs from './components/AboutUs';
import LandingPage from "./components/LandingPage";
import ModelAudit from "./components/ModelAudit";
import BlogPostsPage from './components/BlogPostsPage';
import BlogPostPage from './components/BlogPostPage';
import ProfilePage from './components/ProfilePage';
import CurrentJobs from './components/CurrentJobs';

// import LoadingScreen from './components/LoadingScreen';

import { AuthProvider } from './components/AuthContext';
import { SidebarProvider } from './components/SidebarContext';
import { TooltipVisibilityProvider } from './components/TooltipVisibilityContext';


import ProtectedRoute from './components/ProtectedRoute';
import Footer from './components/Footer';

function App() {

  // const location = useLocation();
  // const [loading, setLoading] = useState(false);
  // const [loadingTimeoutDuration, setLoadingTimeoutDuration] = useState(300);

  // useEffect(() => {
  //   // Function to start and stop loading
  //   const handleNavigationChange = () => {
  //     // Start loading
  //     setLoading(true);

  //     // Stop loading after 300ms
  //     setTimeout(() => setLoading(false), 300);
  //   };

  //   // Trigger the loading function on location change
  //   handleNavigationChange();

  //   // React to future location changes
  //   return () => {
  //     handleNavigationChange();
  //   };
  // }, [location]);

  const location = useLocation();
  const canonicalUrl = `https://www.alaight.org${location.pathname}`;
  
  return (

    <AuthProvider>

      <TooltipVisibilityProvider>

        {/* {loading && <LoadingScreen />} Display the loading screen when loading */}

        <Helmet>
          <link rel="icon" href="%PUBLIC_URL%/favicon_set/favicon.ico" />
          <link rel="canonical" href={canonicalUrl} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>

        <TopRightLogo />
        
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <div className='master-container'>

          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/login" element={<Login />} />
            <Route path="/confirm-email" element={<ConfirmRegistration />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            
            <Route path="/blog" element={<BlogPostsPage />} />
            <Route path="/blog/:postId" element={<BlogPostPage />} />
            
            <Route 
              path="/upload" 
              element={
                <ProtectedRoute>
                  <Upload />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/predictions-analyze" 
              element={
                <ProtectedRoute>
                  <SidebarProvider>
                    <Analyze />
                  </SidebarProvider>
                </ProtectedRoute>
              } 
              />
            <Route 
              path="/audit-model" 
              element={
                <ProtectedRoute>
                  <SidebarProvider>
                    <ModelAudit />
                  </SidebarProvider>
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/update-model" 
              element={
                <ProtectedRoute>
                  <SidebarProvider>
                    <Billing />
                    {/* <UpdateModel /> */}
                  </SidebarProvider>
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/untrain-model" 
              element={
                <ProtectedRoute>
                  <SidebarProvider>
                    {/* <UntrainModel /> */}
                    <Billing />
                  </SidebarProvider>
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/current-processes" 
              element={
                <ProtectedRoute>
                  <SidebarProvider>
                    <CurrentJobs />
                  </SidebarProvider>
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/profile" 
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/auth-password-change" 
              element={
                <ProtectedRoute>
                  <ResetPassword />
                </ProtectedRoute>
              } 
            />
            {/* <Route path="/get-certified" element={<GetCertified />} /> */}
            <Route path="/about" element={<AboutUs />} />
            <Route path="/" element={<div>Home Page</div>} />
          </Routes>
          
        </div>

        <Footer />

      </TooltipVisibilityProvider>

    </AuthProvider>
  );
}

export default App;

