import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './BlogPostsPage.css';

const BlogPostsPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      const apiBaseUrl = process.env.REACT_APP_API_PORT
        ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
        : process.env.REACT_APP_API_URL;

      try {
        const response = await axios.get(`${apiBaseUrl}/api/blogposts`);
        setBlogPosts(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchBlogPosts();
  }, []);

  return (
    <div>
        <h1 className="blog-posts-page-title">Alaight Information</h1>
        <div className="blog-posts-page">
            {blogPosts.map(post => (
                <Link key={post.id} to={`/blog/${post.id}`} className="blog-post-link">
                <div className="blog-post">
                    <img src={post.imageUrl} alt={post.title} className="blog-post-image" />
                    <div className="blog-post-details">
                    <h3 className="blog-title">{post.title}</h3>
                    <p className="blog-description">{post.description}</p>
                    </div>
                </div>
                </Link>
            ))}
        </div>
    </div>
  );
};

export default BlogPostsPage;
