import React, { useState, useEffect, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useNavigate } from 'react-router-dom';

import { useAuthenticatedRequest } from './useAuthenticatedRequest';
import ProfileWidget from './ProfileWidget';
import ErrorMessage from './ErrorMessage';
import { useLogout } from './useLogout';
import Modal from './Modal';

import "./ProfilePage.css";

const ProfilePage = () => {

    // for logging out
    const handleLogout = useLogout();

    // for showing error message
    const [error, setError] = useState('');
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorType, setErrorType] = useState('error');

    const displayError = (message, type = 'error') => {
        setError(message);
        setIsErrorVisible(true);
        setErrorType(type); // Add a state for error type

        setTimeout(() => {
            setIsErrorVisible(false);
        }, 3000);
    };

    // State for API graph data
    const [graphData, setGraphData] = useState([
        { name: '1', value: 1 }, 
        { name: '2', value: 1 }, 
        { name: '3', value: 1 }, 
        { name: '4', value: 1 }, 
        { name: '5', value: 1 }
    ]);

    // fetching information for the graphs through an api every second
    // useEffect(() => {
    //     const fetchGraphData = async () => {
    //         try {
    //             const newData = await makeRequest(`${apiBaseUrl}/api/graph1`, 'get');
    //             setGraphData(newData.map((val, idx) => ({ name: `Time ${idx + 1}`, value: val })));
    //         } catch (error) {
    //             console.error('Error fetching graph data:', error);
    //         }
    //     };

    //     fetchGraphData();
    // }, []);
    
    // getting the alaight image as the profile image as of now
    const apiBaseUrl = process.env.REACT_APP_API_PORT
        ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
        : process.env.REACT_APP_API_URL;
    const profileImageUrl = `${apiBaseUrl}/api/alaight_icon`; // Using the same image URL as ProfileWidget

    // state variables for the profile data that we are showing and using
    const [profileData, setProfileData] = useState({
        name: localStorage.getItem('display_name') || 'User',
        organization: '',
        email: '',
        joinDate: '',
        isCorporate: false,
        role: false
    });

    // editing the profile data
    const [editData, setEditData] = useState({ ...profileData });
    const [isEditing, setIsEditing] = useState(false);

    // create the python code for making a request to the flask backend
    const makeRequest = useAuthenticatedRequest();
    const navigate = useNavigate();

    // getting the profile info on loading the page
    useEffect(() => {
        const fetchProfileInfo = async () => {
            const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;

            try {
                // Replace with actual API call
                const data = await makeRequest(`${apiBaseUrl}/api/profile-information`, 'get');
                // const data = await response.json();
                setProfileData(prevData => ({ ...prevData, ...data }));
                console.log("data: " + data);

            } catch (error) {
                console.error('Error fetching profile information:', error);
            }
        };

        fetchProfileInfo();
    }, []);

    // for turning on and off edit mode of our profile
    const toggleEditMode = () => {
        setIsEditing(!isEditing);
        if (!isEditing) {
            setEditData({ ...profileData });
        }
    };

    // handle edits to the profile when we save it
    const handleEditChange = (field, value) => {
        setEditData({ ...editData, [field]: value });
        setIsEditing(true);
    };

    // state variables and functions for checking whether we should actually be deleting a model or not
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);

    // Confirm delete action
    const confirmDelete = () => {
        setIsDeleteModalOpen(false);
        
        // make a request to delete the user
        const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;

        makeRequest(`${apiBaseUrl}/api/authenticated-delete-user`, "POST");

        // logout
        handleLogout();

        displayError("Deleted User");

    };

    // Confirm reset password action
    const confirmResetPassword = () => {
        setIsResetModalOpen(false);
        navigate('/auth-password-change');
    };


    // save the new information to the profile information api and the server
    const saveProfile = async () => {
        try {
            
            const response = await makeRequest(`${apiBaseUrl}/api/update-profile-information`, 'POST', editData);
            
            if (response.success) {

                console.log("response: " + editData.name);
                
                localStorage.setItem('display_name', editData.name); // Update local storage
                setProfileData(editData);
                setIsEditing(false);

            } else {
                
                console.error('Error updating profile');

            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };
    
    
    return (
        <>
            <ErrorMessage message={error} isVisible={isErrorVisible} type={errorType} />
            <div className="profile-page-container">
                <div className="profile-layout">
                    <div className="left-column">
                        <h2>Profile</h2>
                        <img className="profile-photo" src={profileImageUrl} alt="Profile" />
                        <div className="profile-info">
                            {isEditing ? (
                                <>
                                    <hr />
                                    <input type="text" value={profileData.name} onChange={(e) => handleEditChange('name', e.target.value)} />
                                    <hr />
                                    <p>Organization: {profileData.organization}</p>
                                    <hr />
                                    <input type="text" value={profileData.role} onChange={(e) => handleEditChange('role', e.target.value)} />
                                    <hr />
                                    <p>Email: {profileData.email}</p>
                                    <hr />
                                    <p>Join Date: {profileData.joinDate}</p>
                                    <hr />
                                    <p>Account Type: {editData.isCorporate ? 'Corporate' : 'Personal'}</p>
                                    <hr />
                                </>
                            ) : (
                                <>
                                    <hr />
                                    <p>Name: {profileData.name}</p>
                                    <hr />
                                    <p>Organization: {profileData.organization}</p>
                                    <hr />
                                    <p>Role: {profileData.role}</p>
                                    <hr />
                                    <p>Email: {profileData.email}</p>
                                    <hr />
                                    <p>Join Date: {profileData.joinDate}</p>
                                    <hr />
                                    <p>Account Type: {profileData.isCorporate ? 'Corporate' : 'Personal'}</p>
                                    <hr />
                                </>
                            )}
                        </div>
                        {isEditing ? (
                            <button className="button-class" onClick={saveProfile}>Save</button>
                        ) : (
                            <button className="button-class" onClick={toggleEditMode}>Edit Profile</button>
                        )}
                    </div>
                    <div className="right-column">
                    <div className="content-box">
                        <div className="api-usage-section">
                            <h2>API Usage</h2>
                            <div className="api-usage-boxes">
                                <div className="api-box">
                                    <h3>Requests/min</h3>
                                    <LineChart width={350} height={275} data={graphData} margin={{ top: 5, right: 25, left: 0, bottom: 5 }}>
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="value" stroke="var(--general-purple)" />
                                    </LineChart>
                                </div>
                                <div className="api-box">
                                    <h3>Latency per Prediction</h3>
                                    <LineChart width={350} height={275} data={graphData} margin={{ top: 5, right: 25, left: 0, bottom: 5 }}>
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="value" stroke="var(--general-purple)" />
                                    </LineChart>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                        <div className="content-box">
                            <div className="billing-section">
                                <h2>Billing</h2>
                                <div className="billing-columns">
                                    <div className="billing-column">
                                        <h3>Current Session</h3>
                                        <p>Time Used: </p> {/* Placeholder */}
                                        <p>Models Trained: </p> {/* Placeholder */}
                                        <p>Amount Due: </p> {/* Placeholder */}
                                    </div>
                                    <div className="billing-column">
                                        <h3>All-Time</h3>
                                        <p>Time Used: </p> {/* Placeholder */}
                                        <p>Models Trained: </p> {/* Placeholder */}
                                        <p>Predictions Made: </p> {/* Placeholder */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div>
                    <ProfileWidget />
                </div>
                
                <div style={{ marginTop: "7vh" }}>
                    <div>
                        <button className="button-class" onClick={() => setIsDeleteModalOpen(true)}>Delete Account</button>
                        <button className="button-class" onClick={() => setIsResetModalOpen(true)}>Reset Password</button>
                    </div>
                    <Modal 
                        isOpen={isDeleteModalOpen}
                        title="Confirm Account Deletion"
                        content="Are you sure you want to delete your account? This action cannot be undone."
                        onConfirm={confirmDelete}
                        onCancel={() => setIsDeleteModalOpen(false)}
                    />

                    <Modal 
                        isOpen={isResetModalOpen}
                        title="Confirm Password Reset"
                        content="Are you sure you want to reset your password?"
                        onConfirm={confirmResetPassword}
                        onCancel={() => setIsResetModalOpen(false)}
                    />
                </div>
            </div>
        </>
    );
};

export default ProfilePage;
