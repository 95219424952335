import React, { useState, useEffect } from 'react';
import { useAuthenticatedRequest } from './useAuthenticatedRequest';

function ProgressBar({ isCreating, setIsCreating, filename }) {
    const [progress, setProgress] = useState(0);
    const makeRequest = useAuthenticatedRequest();

    useEffect(() => {
        const updateProgress = async () => {
            if (isCreating && filename) {
                const apiBaseUrl = process.env.REACT_APP_API_PORT
                    ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                    : process.env.REACT_APP_API_URL;

                try {
                    const progressFilename = filename.endsWith('.json') ? filename : `${filename}.json`;
                    const response = await makeRequest(`${apiBaseUrl}/api/track_model_creation_progress?filename=${progressFilename}`, 'get');
                    setProgress(response.progress);
                    setIsCreating(response.generating);
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        };

        // Immediate update on mount
        updateProgress();

        // Set interval for subsequent updates
        const interval = setInterval(updateProgress, 3000);
        return () => clearInterval(interval);
    }, [isCreating, setIsCreating, filename, makeRequest]);

    return (
        <div>
            <p className='label-box' style={{ color: "#fff", textAlign: 'left', marginTop: '8px' }}>{progress}%</p>
            <div className='progress-bar' style={{width: `${progress * 0.92}%`, padding: "10px", borderRadius: "10px", border: "3px solid #ccc", marginTop: "20px", backgroundColor: "rgb(101, 0, 101)" }}>
            </div>
        </div>
    );
}

export default ProgressBar;
