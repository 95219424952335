import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext'; // Import the AuthContext
import { useAuthenticatedRequest } from './useAuthenticatedRequest';
import { defaultPasswords, validatePassword } from './PasswordUtils';

import "../App.css"

const ResetPassword = () => {
    
    // info for requests
    const navigate = useNavigate();
    const { token } = useParams();
    const auth = useContext(AuthContext); // Use the AuthContext

    // defining the make requests variable
    const makeRequest = useAuthenticatedRequest();

    // state variables
    const [newPassword, setNewPassword] = useState('');
    const [newConfirmPassword, setNewConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    // password validation state
    const [passwordValidation, setPasswordValidation] = useState({
        lengthCheck: false,
        numberOrSpecialCharCheck: false,
        notDefaultPassword: true,
        samePassword: false,
    });

    useEffect(() => {
        setPasswordValidation(validatePassword(newPassword, newConfirmPassword));
    }, [newPassword, newConfirmPassword]);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (newPassword !== newConfirmPassword) {
            setMessage("Passwords do not match");
            return;
        }

        // Check if the user is authenticated
        const isAuthenticated = auth && auth.isAuthenticated();

        try {
            
            
            let response;
            if (isAuthenticated) {
                
                // User is authenticated, use makeRequest function
                const apiBaseUrl = process.env.REACT_APP_API_PORT
                ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
                : process.env.REACT_APP_API_URL;
                
                // make a request and get a response for authenticated password reset
                response = await makeRequest(`${apiBaseUrl}/api/authenticated-reset-password`, 'POST', { new_password: newPassword });
                
            } else {
                // User is not authenticated, use axios for token-based reset
                response = await axios.post(`/api/reset-password/${token}`, 
                    JSON.stringify({ new_password: newPassword }), 
                    { headers: { 'Content-Type': 'application/json' } }
                );
            }

            if (response.success) {
                setMessage('Password successfully updated');
                navigate(isAuthenticated ? "/profile" : "/login");
            } else {
                setMessage('Failed to update password.');
            }
        } catch (error) {
            setMessage('Failed to update password.');
        }
    };


    return (
        <div>
            <title>Reset Password</title>
            <meta name="description" content="Reset the password for Alaight account."/>

            <div className="auth-container">

                <div className="header-section">
                    <h1>Reset Password</h1>
                </div>

                <div className="login-section">
                    {/* <h2>Reset Password</h2> */}
                    <div className="input-group">
                        <input
                            className="text-box"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Enter new password"
                            required
                            name="userEmail"
                        />
                    </div>
                    <div className="input-group">
                        <input
                            name="secretKey"
                            className="text-box"
                            type="password"
                            value={newConfirmPassword}
                            onChange={(e) => setNewConfirmPassword(e.target.value)}
                            placeholder="Confirm new password"
                            required
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: "center" }}>
                        <div className="password-validation-feedback-box">
                            <div className="password-validation-feedback">
                                <p style={{ color: passwordValidation.lengthCheck ? 'green' : 'red' }}>Minimum 8 characters</p>
                                <p style={{ color: passwordValidation.numberOrSpecialCharCheck ? 'green' : 'red' }}>Contains number or special character</p>
                            </div>
                            <div className="password-validation-feedback">
                                <p style={{ color: passwordValidation.notDefaultPassword ? 'green' : 'red' }}>Not a default password</p>
                                <p style={{ color: passwordValidation.samePassword ? 'green' : 'red' }}>Passwords Match</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "center", width: "100%" }}>
                        <button className="button-class" style={{ cursor: "pointer" }} onClick={handleSubmit}>Update Password</button>
                    </div>
                </div>
            </div>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ResetPassword;
